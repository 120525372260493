<template>
  <div class=" container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-xl justify-between items-end">
        <div class="text-h4">News</div>
        <MediaSelect class="col-3" v-bind:selection.sync="selection" label="Showing News for:" isMedia includeCompany v-if="$store.getters['auth/homePageFilter']" />
      </div>

      <div class="row q-gutter-xl justify-between">
        <q-btn @click="showModal = true" color="primary">Add News</q-btn>
      </div>

      <div class="row q-mt-xl">
        <div v-if="news.length > 0" class="col-xs-12">
          <q-list class="full-width q-pb-xl">
            <q-item v-for="article in news" :key="article.uid">
              <q-item-section class="col-2">
                <q-item-label>{{ article.publish_at.slice(0, 10) }}</q-item-label>
              </q-item-section>
              <q-item-section>
                <q-item-label :inner-html.prop="article.title"></q-item-label>
              </q-item-section>
              <q-item-section class="col-2">
                <q-btn flat @click="copyURL(article.uid)">Copy URL</q-btn>
              </q-item-section>
              <q-item-section class="col-1">
                <q-btn flat @click="selectForEdit(article)">Edit</q-btn>
              </q-item-section>
              <q-item-section class="col-1">
                <q-btn flat @click="removeNews(article)" :loading="(deleting === article.uid)">Delete</q-btn>
              </q-item-section>
            </q-item>
          </q-list>
          <div class="row full-width justify-center q-mt-lg">
            <q-pagination v-if="pageMax > 1" v-model="page" :max="pageMax" :max-pages="10" :direction-links="true" />
          </div>
        </div>
        <div v-else class="col-xs-12">
          <div align="center">There are currently no news articles for this Location or User Group.</div>
        </div>
      </div>

      <q-dialog v-model="showModal" maximized @hide="mode = 'create'">
        <q-card class="q-pa-lg bg-lmsBackground primaryText-text">
          <q-form @submit="mode === 'create' ? addNews() : saveNews()" @reset="showModal = false" autocomplete="off">
            <div class="row justify-end">
              <q-btn dense flat icon="close" v-close-popup>
                <q-tooltip>Close</q-tooltip>
              </q-btn>
            </div>
            <q-card-section>
              <div class="text-h5">{{ mode === 'create' ? 'Create' : 'Edit' }} News Article</div>
            </q-card-section>
            <q-card-section>
              <div class="col-xs-12 q-mb-lg">
                <q-input label="Title" type="text" v-model.trim="article.title" :rules="formRules.title" />
              </div>
              <div class="col-xs-12 q-mb-lg">
                <div class="text-body2 text-italic q-mb-xs">*To add an image, drag and drop an image file or online image into the text box</div>
                <tinymce-editor v-model="article.body" :init="tinyOptions"></tinymce-editor>
              </div>
              <div class="col-xs-12 q-mb-lg">
                <q-input @click="$refs.qPublishDateProxy.show()" mask="##/##/####" fill-mask filled label="Publish Date" v-model="article.publish_at" :rules="formRules.pubDate">
                  <template v-slot:prepend>
                    <q-icon name="event" class="cursor-pointer primaryText-text">
                      <q-popup-proxy ref="qPublishDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date today-btn v-model="article.publish_at" @input="$refs.qPublishDateProxy.hide()" mask="MM/DD/YYYY" class="bg-lmsBackground primaryText-text" />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="col-xs-12 q-mb-lg">
                <q-input @click="$refs.qExpiresDateProxy.show()" mask="##/##/####" fill-mask filled label="Expiration Date" v-model="article.expires_at">
                  <template v-slot:prepend>
                    <q-icon name="event" class="cursor-pointer primaryText-text">
                      <q-popup-proxy ref="qExpiresDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date today-btn v-model="article.expires_at" @input="$refs.qExpiresDateProxy.hide()" mask="MM/DD/YYYY" class="bg-lmsBackground primaryText-text" />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="col-xs-12 q-mb-lg" v-if="$store.getters['auth/homePageFilter']">
                <MediaSelect v-bind:selection.sync="article.assigned_uid" label="Assign To" class="full-width" />
              </div>
            </q-card-section>
            <div class="row gutter-xs q-my-lg q-mx-lg">
              <div class="col-12">
                <q-btn type="submit" class="float-right" :loading="saving" color="primary">{{ mode === 'create' ? 'Add' : 'Save' }}</q-btn>
                <q-btn type="reset" flat class="float-right on-left">Cancel</q-btn>
              </div>
            </div>
          </q-form>
        </q-card>
      </q-dialog>

    </div>
  </div>
</template>

<script>
import { date } from 'quasar'
import Editor from '@tinymce/tinymce-vue'
import MediaSelect from 'components/MediaSelect'

export default {
  components: { 'tinymce-editor': Editor, MediaSelect },
  data () {
    return {
      newsItems: [],
      tinyOptions: {
        height: 500,
        branding: false,
        toolbar: 'undo redo | styleselect | fontselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table link',
        plugins: [
          'advlist autolink lists preview',
          'searchreplace visualblocks fullscreen',
          'paste imagetools wordcount table link'
        ],
        fontsize_formats: '6pt 8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 36pt 42pt 60pt',
        paste_data_images: true,
        skin: false
      },
      assignOptions: [
        { label: 'Company', id: 'Company' },
        { label: 'Location', id: 'Location', children: this.$store.getters['locations/treeList'] },
        { label: 'User Group', id: 'Department', children: this.$store.getters['departments/treelist'] }
      ],

      mode: 'create',
      saving: false,
      deleting: null,
      showModal: false,
      article: {
        title: null,
        body: null,
        publish_at: date.formatDate(new Date(), 'MM/DD/YYYY hh:mm a'),
        expires_at: null,
        assigned_uid: 'Company',
        commentable: true
      },
      selection: null,
      page: 1,
      perPage: 10
    }
  },
  computed: {
    formRules: function () {
      return {
        title: [ this.$formRules.required('Title') ],
        pubDate: [ this.$formRules.required('Publish Date') ]
      }
    },
    filteredNews: function () {
      return this.$store.getters['news/getByAssignedUid'](this.newsItems, this.selection)
    },
    pageMax () {
      if (this.filteredNews.length === 0) return 0
      return Math.ceil(this.filteredNews.length / this.perPage)
    },
    news () {
      let startIndex = (this.page - 1) * this.perPage
      return this.filteredNews.slice(startIndex, startIndex + this.perPage)
    }
  },
  watch: {
    'article.publish_at' (val) {
      this.article.publish_at = date.formatDate(new Date(val), 'MM/DD/YYYY hh:mm a')
    },
    'article.expires_at' (val) {
      this.article.expires_at = date.formatDate(new Date(val), 'MM/DD/YYYY hh:mm a')
    },
    'showModal' (val) {
      if (!val) {
        this.article = {
          title: null,
          body: null,
          publish_at: date.formatDate(new Date(), 'MM/DD/YYYY hh:mm a'),
          display_order: 1,
          expires_at: null,
          assigned_uid: this.$isAllowed(['||', 'master-admin', 'site-admin'], true) ? 'Company' : this.$store.getters['auth/customer'],
          commentable: true
        }
      }
    }
  },
  methods: {
    copyURL (uid) {
      const el = document.createElement('textarea')
      el.value = 'https://' + document.domain + '/news/' + uid
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.$successMsg('URL Copied to Clipboard')
    },
    addNews () {
      if (!this.article.body) {
        this.$failedValidationMsg('Please add a News Body')
        return
      }
      this.saving = true
      this.$store.dispatch('news/add', this.article).then((response) => {
        this.newsItems.unshift(response)
        this.saving = false
        this.showModal = false
        this.$successMsg()
      }).catch((e) => {
        this.saving = false
        this.$failureMsg()
      })
    },
    saveNews () {
      if (!this.article.body) {
        this.$failedValidationMsg('Please add a News Body')
        return
      }
      this.saving = true
      this.$store.dispatch('news/update', this.article).then((response) => {
        this.$set(this.newsItems, this.newsItems.findIndex(news => news.uid === this.article.uid), response)
        this.saving = false
        this.showModal = false
        this.$successMsg()
      }).catch((e) => {
        this.saving = false
        this.$failureMsg()
      })
    },
    selectForEdit ({ ...article }) {
      this.mode = 'edit'
      this.article = article
      this.showModal = true
    },
    removeNews (newsItem) {
      this.deleting = newsItem.uid
      this.$store.dispatch('news/remove', newsItem.uid).then((response) => {
        this.$delete(this.newsItems, this.newsItems.findIndex(news => news.uid === newsItem.uid))
        this.$successMsg()
        this.deleting = null
      }).catch((e) => {
        this.$failureMsg()
        this.deleting = null
      })
    }
  },
  created () {
    this.$q.loading.show({})
    this.$store.dispatch('news/fetch').then((response) => {
      this.newsItems = response
      this.$q.loading.hide()
    })
    this.selection = this.$isAllowed(['||', 'master-admin', 'site-admin'], true) ? 'Company' : this.$store.getters['auth/customer']
    this.article.assigned_uid = this.$isAllowed(['||', 'master-admin', 'site-admin'], true) ? 'Company' : this.$store.getters['auth/customer']
  }
}
</script>
